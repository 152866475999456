@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

.subtitle {
   margin: 0;
   font-weight: 600;
   font-size: 48px;
   line-height: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 123px;
   color: var(--blue);
   &__white {
      color: var(--grey);
      height: 155px;
   }
}

@media (max-width: 768px) {
   .subtitle {
      font-size: 20px;
      line-height: 25px;
   }
}

@import "./components/header";
@import "./components/_hero";
@import "./components/_abouth";
@import "./components/_reserch";
@import "./components/_activity";
@import "./components/_help";
@import "./components/_footer";

.footer {
   background-color: var(--black-blue);
   padding-bottom: 150px;
   &__wrapper {
      display: grid;
      grid-template-areas: "connect form";
      grid-template-columns: repeat(2, 1fr);
   }
   &__connection {
      grid-area: connect;
   }
   &__title {
      margin-top: 68px;
      margin-bottom: 28px;
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      color: var(--grey);
   }
   &__link {
      display: flex;
      &:hover {
         .footer__link__logo {
            background-color: var(--blue);
         }
         .footer__link__text {
            color: var(--blue);
         }
      }
      &__logo {
         background-color: #fff;
         height: 40px;
         width: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         margin-right: 21px;
         margin-bottom: 37px;
         transition: all 0.3s ease-out;
      }
      &__text {
         font-family: var(--font-family-New);
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         color: var(--grey);
         transition: all 0.3s ease-out;
      }
   }
   &__form {
      margin-top: 46px;
      grid-area: form;
      &__title {
         display: none;
      }
      label {
         font-family: var(--font-family-New);
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         color: var(--grey);
      }
      input {
         font-family: var(--font-family-New);
         width: 100%;
         box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
         border-radius: 6px;
         padding: 12px 16px;
         margin-bottom: 47px;
      }
      textarea {
         font-family: var(--font-family-New);
         width: 100%;
         box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
         border-radius: 6px;
         padding: 12px 16px;
         margin-bottom: 47px;
      }
      button {
         font-weight: 500;
         font-size: 20px;
         line-height: 25px;
         width: 100%;
         background-color: var(--black-blue);
         color: var(--grey);
         border: 2px solid var(--grey);
         border-radius: 30px;
         padding: 9px 0;
         cursor: pointer;
         transition: all 0.3s ease-out;
         &:hover {
            background-color: var(--grey);
            color: var(--black-blue);
         }
      }
   }
   &__btn {
      &-min {
         display: none;
      }
   }
}

@media (max-width: 1130px) {
   .footer {
      padding: 0 20px;
   }
}

@media (max-width: 768px) {
   .footer {
      border-radius: 20px 0px 0px 0px;
      &__wrapper {
         grid-template-areas: "form"
                              "connect";
         grid-template-columns: repeat(1, 1fr);
         grid-template-rows: repeat(2, auto);
      }
      &__form {
         margin-top: 16px;
         &__title {
            display: block;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--grey);
         }
         label {
            display: none;
         }
         input {
            margin-bottom: 16px;
            &::placeholder {
               font-weight: 400;
               font-size: 11px;
               line-height: 15px;
            }
         }
         textarea {
            margin-bottom: 14px;
            height: 80px;
            &::placeholder {
               font-weight: 400;
               font-size: 11px;
               line-height: 15px;
            }
         }
      }
      &__btn {
         display: none;
         &-min {
            font-weight: 400 !important;
            font-size: 13px !important;
            line-height: 16px !important;
            width: 116px !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
         }
      }
      &__title {
         margin-top: 26px;
         margin-bottom: 15px;
         text-align: center;
         font-size: 20px;
         line-height: 25px;
      }
      &__link {
         justify-content: center;
         &__text {
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
         }
         &__logo {
            height: 16px;
            width: 16px;
            margin-bottom: 16px;
            margin-right: 9px;
            svg {
               height: 10px;
               width: 10px;
            }
         }
      }
   }
}
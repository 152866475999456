.header {
   .container {
      position: relative;
   }
   &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      display: flex;
      justify-content: space-between;
      padding-top: 44px;
   }
   &__links {
      display: flex;
      gap: 92px;
   }
   &__link {
      position: relative;
      color: var(--black-blue);
      transition: all 0.3s ease-out;
      &:hover {
         color: var(--blue);
         .header__submenu {
            display: block;
         }
      }
   }
   &__submenu {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: var(--blue);
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: none;
      &__link {
         a {
            color: var(--light-color);
            &:hover {
               color: var(--black-blue);
            }
         }
      }
   }
   &__burger {
      position: absolute;
      display: none;
      top: 20px;
      right: 20px;
      &__btn {
         position: relative;
         z-index: 100000;
         .line {
            height: 2px;
            width: 30px;
            margin-bottom: 10px;
            background-color: var(--light-color);
            transition: all 0.3s ease-out;
            &.act {
               transform: rotate(45deg) translateY(8px);
            }
            &:last-child {
               margin-bottom: 0;
               &.act {
                  transform: rotate(-45deg) translateY(-8px);
               }
            }
         }
      }
      &__menu {
         display: none;
         position: absolute;
         top: 0;
         left: 0;
         width: 100vw;
         height: 100vh;
         background-color: var(--blue);
         z-index: 10;
         &.activ {
            display: block;
         }
      }
   }
   &__menu {
      height: 100vh;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      &__link {
         a {
            color: var(--light-color);
         }
      }
      &__submenu {
         list-style-type: none;
         margin: 0;
         padding: 0;
         display: flex;
         flex-direction: column;
         gap: 20px;
      }
   }
}

@media (max-width: 768px) {
   .header__wrapper {
      display: none;
   }
   .header__burger {
      display: block;
   }
}